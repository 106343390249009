//import '../vendor/bootstrap-datetimepicker'
//import 'moment/locale/es'

$(() => {
  let options = {
    singleDatePicker: true,
    timePicker:true,
    timePicker24Hour: true,
    showDropdowns: true,
    autoUpdateInput: false,
    timePickerSeconds: false,
    locale: {
      format: trans.trans('daterangepicker.format'),
      applyLabel: trans.trans('daterangepicker.applyLabel'),
      cancelLabel: trans.trans('daterangepicker.cancelLabel'),
      fromLabel: trans.trans('daterangepicker.fromLabel'),
      toLabel: trans.trans('daterangepicker.toLabel'),
      customRangeLabel: trans.trans('daterangepicker.customRangeLabel'),
      weekLabel: trans.trans('daterangepicker.weekLabel'),
      daysOfWeek: JSON.parse(trans.trans('daterangepicker.daysOfWeek')),
      monthNames: JSON.parse(trans.trans('daterangepicker.monthNames')),
      firstDay: Number.parseInt(trans.trans('daterangepicker.firstDay')),
    },
  }

  let datetimepicker = $('[data-autoinit="datetimepicker"]')

  datetimepicker.each((i, el) => {
    // TODO: Pensar si se puede mejorar esto para no tener que tratar cada configuración por separado
    const format = $(el).data('format')

    if (format) {
      options.locale.format = format
    }

    const noTimePicker = typeof($(el).data('no-show-time')) !== 'undefined'
    options.timePicker = noTimePicker ? false : options.timePicker

    $(el).daterangepicker(options)
    const picker=$(el).data('daterangepicker');
    if ($(el).val()) {
      $(el).val(picker.startDate.format(options.locale.format));
    }
  })

  datetimepicker.on('cancel.daterangepicker', function(ev, picker) {
    $(this).val('');
  });

  datetimepicker.on('apply.daterangepicker', function(ev, picker) {
    let format = $(this).data().locale.format
    
    $(this).val(picker.startDate.format(format));
  });

})
